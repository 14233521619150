<template>
  <div class="auth-form table-view">

    <div class="card">
      <h5 class="card-header">Pending Users - These Users Are Not Approved As Yet</h5>
      <div class="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Email</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="User in PendingUsers" :key="User._id">
              <td>{{User.UserEmail}}</td>
              <td>{{User.UserFirstName}}</td>          
              <td>{{User.UserLastName}}</td>
              <td>{{moment(User.UserCreated).fromNow()}}</td>
              <td><router-link class="btn btn-sm btn-outline-success" :to="'/admin-manage-user/' + User._id">Manage</router-link></td>
            </tr>
          </tbody>
        </table>        
      </div>
    </div>                        
  </div>
</template>

<script>

import { onMounted, ref, watch } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");

export default {
  setup() {

    const PendingUsers = new ref([])

    onMounted(() => {

      axios.get("/api/AdminGetPendingUsers").then((Result) => {
        if(Result.data.Success){
          PendingUsers.value = Result.data.Users          
        }else{
          console.log("Err")
        }
      }).catch((Error) => {
        console.log(Error)
        if(Error.response.status !== undefined || Error.response.status === 401){
          router.push({name: 'home'})
        }
      })

    })


    return {PendingUsers, moment};
  },
};
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}

.no-click:hover{
  cursor: default;
}
</style>