<template>
  <div class="auth-form table-view">   
    <div class="card">
      <h5 class="card-header">Filters For Table Below</h5>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6 mb-3">
            <label>Search By Travel Agency Name</label>
            <div class="input-group">              
              <input v-model="Input_SearchByTAName" type="text" class="form-control" placeholder="Start Typing To Search" aria-describedby="button-addon2">
              <button @click="HandleReset" class="btn btn-info" type="button" id="button-addon2">Reset</button>
            </div>            
          </div>
          <div class="col-sm-6 mb-3">
            <label>Search By Travel Agency Manager</label>
            <div class="input-group">              
              <input v-model="Input_SearchByTAMANName" type="text" class="form-control" placeholder="Start Typing To Search" aria-describedby="button-addon3">
              <button @click="HandleReset" class="btn btn-info" type="button" id="button-addon3">Reset</button>
            </div>            
          </div>          
        </div>
        <div class="row">
          <div class="col-sm-2">            
            <button class="btn btn-primary w-100">Active</button>          
          </div>
          <div class="col-sm-2">            
            <button class="btn btn-secondary w-100">InActive</button>          
          </div>
        </div>
      </div>
    </div>
    <br> 
    <div class="card">
      <h5 class="card-header">All Travel Agencies</h5>
      <div class="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Trading As</th>
              <th>Country</th>
              <th>Manager</th>
              <th>Contact</th>
              <th>Email</th>
              <th>Active</th>
              <th>Action</th>
              <th>View Users</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="TA in FilteredTAs" :key="TA._id">
              <td>{{TA.TAName}}</td>
              <td>{{TA.TATradingAs}}</td>
              <td>{{TA.TACountry}}</td>
              <td>{{TA.TAManagerName}}</td>
              <td>{{TA.TAManagerContactNumber}}</td>
              <td>{{TA.TAManagerEmail}}</td>
              <td v-if="TA.TAActive"><button type="button" class="btn btn-sm btn-success">Yes</button></td>
              <td v-else><button type="button" class="btn btn-sm btn-danger">No</button></td>
              <td><router-link class="btn btn-sm btn-info" :to="'/admin-manage-travel-agency/' + TA._id">Manage</router-link></td>
              <td><router-link class="btn btn-sm btn-primary w-100" :to="'/admin-travel-agency-users/' + TA._id">View</router-link></td>
            </tr>
          </tbody>
        </table>        
      </div>
    </div>
    <br>
  </div>
</template>

<script>

import { onMounted, ref, watch } from "vue";
import axios from "axios";
import router from "@/router";

export default {
  name: "AdminTAsView",
  setup() {

    const TAs = new ref([])
    const FilteredTAs = new ref([])
    const ErrorMessage = new ref('')

    onMounted(() => {

      axios.get("/api/AdminGetAllTAs").then((Result) => {
        if(Result.data.Success){
          TAs.value = Result.data.TAs   
          FilteredTAs.value = Result.data.TAs
        }else{
          console.log("Err")
        }
      }).catch((Error) => {
        console.log(Error)
        if(Error.response.status !== undefined || Error.response.status === 401){
          router.push({name: 'home'})
        }
      })

    })

    const HandleReset = () => {

      Input_SearchByTAMANName.value = ''
      Input_SearchByTAName.value = ''

      FilteredTAs.value = TAs.value

    };

    const Input_SearchByTAName = new ref('')
    const Input_SearchByTAMANName = new ref('')

    watch(Input_SearchByTAMANName, () => {

      FilteredTAs.value = TAs.value.filter((a) => {
        return a.TAManagerName.includes(Input_SearchByTAMANName.value.toUpperCase())
      })//.slice can be added

    })

    watch(Input_SearchByTAName, () => {
      FilteredTAs.value = TAs.value.filter((a) => {
        return a.TAName.includes(Input_SearchByTAName.value.toUpperCase()) || (a.TATradingAs.includes(Input_SearchByTAName.value.toUpperCase()))
      })//.slice can be added
    })

    return {
      ErrorMessage,      
      HandleReset,
      FilteredTAs,
      Input_SearchByTAName,
      Input_SearchByTAMANName
    };
  },
};
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}

.no-click:hover{
  cursor: default;
}
</style>