<template>
<div class="normal-view">
  <div class="auth-form">
    <div ref="card1" class="card border-primary">
      <h5 class="card-header">My Profile</h5>
      <div class="card-body">
        <form @submit.prevent="HandleUpdate">
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Email Address</label>
            <input disabled required v-model="Input_Email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            <small class="text-muted">Please contact support to change your Email Address</small>
          </div>
          <div class="mb-3">
            <label for="frmFirstName" class="form-label">First Name</label>
            <input required v-model="Input_FirstName" type="text" class="form-control" id="frmFirstName" />
          </div>
          <div class="mb-3">
            <label for="frmLastName" class="form-label">Last Name</label>
            <input required v-model="Input_LastName" type="text" class="form-control" id="frmLastName" />
          </div>  
          <div class="mb-3">
            <label for="frmUserCreated" class="form-label">Profile Created</label>
            <input disabled v-model="Input_Created" type="text" class="form-control" id="frmUserCreated" />
          </div>             
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">Password</label>            
            <input required v-model="Input_Password" type="password" class="form-control" id="exampleInputPassword1" />
            <small class="text-muted">Please enter your Password to update your profile</small>
          </div> 
          <div class="mb3">            
            <button disabled ref="UpdateButton" type="submit" class="btn btn-primary">Update</button>    
          </div> 
          <div v-if="ErrorMessage">
            <hr>
            <div class="alert alert-danger">
              {{ErrorMessage}}
            </div>
          </div>      
        </form>
      </div>      
    </div>
  </div>

  <!-- Profile update form -->

  <div class="auth-form">
    <div ref="card2" class="card border-primary">
      <h5 class="card-header">Change Password</h5>
      <div class="card-body">
        <form @submit.prevent="HandleUpdatePW">           
          <div class="mb-3">
            <label class="form-label">Old Password</label>            
            <input required v-model="Input_C_OldPassword" type="password" class="form-control" id="frmOldPassword" />
            <small class="text-muted">Please enter your old Password</small>
          </div> 
          <div class="mb-3">
            <label class="form-label">New Password</label>            
            <input minlength="8" required v-model="Input_C_NewPassword" type="password" class="form-control" id="frmNewPassword" />
            <small class="text-muted">Please enter a new Password</small>
          </div> 
          <div class="mb-3">
            <label class="form-label">New Password Confirm</label>            
            <input minlength="8" required v-model="Input_C_NewPasswordConfirm" type="password" class="form-control" id="frmNewPasswordC" />
            <small class="text-muted">Please confirm new Password</small>
          </div> 
          <div class="mb3">            
            <button disabled ref="ChangeButton" type="submit" class="btn btn-info">Change</button>    
          </div> 
          <div v-if="ErrorMessage2">
            <hr>
            <div class="alert alert-danger">
              {{ErrorMessage2}}
            </div>
          </div>      
        </form>
      </div>      
    </div>
  </div>
</div>
</template>

<script>

import { onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");

export default {
  name: "ProfileView",
  setup() {

    const Input_Email = ref('')
    const Input_FirstName = ref('')
    const Input_LastName = ref('')
    const Input_Created = ref('')
    const Input_Password = ref('')
    const ErrorMessage = ref('')
    const UpdateButton = ref(null)
    const card1 = new ref(null)

    //Load data into the form first
    onMounted(() => {
      
      axios.get('/api/UserProfile').then((Result) => {
        if(Result.data.Success){

          Input_Email.value = Result.data.Email
          Input_FirstName.value = Result.data.FirstName
          Input_LastName.value = Result.data.LastName
          Input_Created.value = moment(Result.data.Created).fromNow()
          UpdateButton.value.disabled = false          
          ChangeButton.value.disabled = false

        }else{
          ErrorMessage.value = Result.data.Reason
        }
      }).catch((Error) => {
        console.log(Error)
        ErrorMessage.value = "An API Error Occured. Please Try Again Later Or Contact Admin"
      })

    })

    const HandleUpdate = () => {
      
      const frmFirstName = Input_FirstName.value
      const frmLastName = Input_LastName.value
      const frmPassword = Input_Password.value


      UpdateButton.value.textContent = "Please Wait..."
      ErrorMessage.value = ""

      axios.post("/api/UpdateUserProfile", {frmFirstName, frmLastName, frmPassword}).then((Result) => {
        if(Result.data.Success){
          UpdateButton.value.classList.remove("btn-primary")  
          UpdateButton.value.classList.add("btn-success")  
          UpdateButton.value.textContent = "Done"
          card1.value.classList.remove("border-primary")  
          card1.value.classList.add("border-success") 
        }else{
          ErrorMessage.value = Result.data.Reason
          UpdateButton.value.textContent = "Update"
        }
      }).catch((Error) => {
        console.log(Error)
        UpdateButton.value.textContent = "Update"
        ErrorMessage.value = "An API Error Occured. Please Try Again Later Or Contact Admin"
      });

    }

    const Input_C_OldPassword = new ref('')
    const Input_C_NewPassword = new ref('')
    const Input_C_NewPasswordConfirm = new ref('')
    const ChangeButton = new ref(null)
    const ErrorMessage2 = new ref('')
    const card2 = new ref(null)

    const HandleUpdatePW = () => {

      const frmOldPassword = Input_C_OldPassword.value
      const frmNewPassword = Input_C_NewPassword.value
      const frmNewPasswordC = Input_C_NewPasswordConfirm.value

      //Check new password
      if(frmNewPassword.length < 8){
        ErrorMessage2.value = "Password Must Be At Least 8 Characters"
        return;
      }

      if(frmNewPassword !== frmNewPasswordC){
        ErrorMessage2.value = "Your Passwords Do Not Match"
        return;
      }

      if(frmOldPassword === frmNewPassword){
        ErrorMessage2.value = "New Password Can Not Be Same As Old Password"
        return;
      }

      ErrorMessage2.value = ""
      ChangeButton.value.textContent = "Please Wait..."

      axios.post('/api/UpdateUserSecret', {frmOldPassword, frmNewPassword}).then((Result) => {
        if(Result.data.Success){
          ChangeButton.value.classList.remove("btn-info")  
          ChangeButton.value.classList.add("btn-success")  
          ChangeButton.value.textContent = "Done"
          card2.value.classList.remove("border-primary")  
          card2.value.classList.add("border-success")          
        }else{
          ErrorMessage2.value = Result.data.Reason
          ChangeButton.value.textContent = "Update"
        }
      }).catch((Error) => {
        console.log(Error)
        ChangeButton.value.textContent = "Change"
        ErrorMessage2.value = "An API Error Occured. Please Try Again Later Or Contact Admin"
      })



    }

    return {
      card1,
      card2,
      Input_C_OldPassword,
      Input_C_NewPassword,
      Input_C_NewPasswordConfirm,
      ChangeButton,
      ErrorMessage2,
      HandleUpdatePW,
      HandleUpdate,
      Input_Email,
      Input_FirstName,
      Input_LastName,
      Input_Created,
      Input_Password,
      ErrorMessage,
      UpdateButton
    };
  },
};
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
  padding-bottom: 3rem;
}
</style>