<template>
  <section class="vh-100">
  <div class="container-fluid h-custom">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-9 col-lg-6 col-xl-5">
        <img src="../../assets/login/pic.jpg" class="img-fluid" alt="Sample image">
      </div>
      <div class="col-md-8 col-lg-6 col-xl-4">

        <h1 class="text-white">A2Z Travel Groups</h1>
        <span class="lead text-white">Agents Portal</span>
        
        <hr>

        <form @submit.prevent="HandleLogin">

          <!-- Email input -->
          <div class="form-outline mb-4">
            <label class="form-label text-white" for="frmEmail">Email Address</label>
            <input v-model="Input_Email" required type="email" id="frmEMail" class="form-control form-control-lg" placeholder="Email Address" />
          </div>

          <!-- Password input -->
          <div class="form-outline mb-3">
            <label class="form-label text-white" for="frmPassword">Password</label>
            <div class="input-group">
              <input v-model="Input_Password" type="password" class="form-control form-control-lg" placeholder="Password" />
              <router-link to="/forgot-password" class="btn btn-danger">Forgot?</router-link>      
            </div>            
          </div>

          <div v-if="ErrorMessage" class="form-outline mb-3">
            <div class="alert alert-danger">{{ErrorMessage}}</div>
          </div>

          <div class="text-center text-lg-start mt-4 pt-2">
            <button ref="LoginButton" type="submit" class="btn btn-primary btn-lg" style="padding-left: 2.5rem; padding-right: 2.5rem;">Login</button>            
          </div>

          <div class="text-center text-lg-start mt-4 pt-2">
            <router-link to="/register" class="btn btn-info btn-lg" style="padding-left: 2.5rem; padding-right: 2.5rem;">Register?</router-link>          
          </div>

        </form>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
    <div class="text-white mb-3 mb-md-0">Copyright © 2022. All rights reserved.</div>
  </div>
</section>
</template>

<script>

import { onMounted, ref } from "vue"
import axios from "axios"
import router from "@/router"


export default {
    setup(){

        const Input_Email = new ref('')
        const Input_Password = new ref('')
        const ErrorMessage = new ref('')
        const LoginButton = new ref(null)

        const HandleLogin = () => {
          
          LoginButton.value.disabled = true
          LoginButton.value.textContent = "Authenticating..."
          ErrorMessage.value = ""

          const frmEmail = Input_Email.value
          const frmPassword = Input_Password.value

          axios.post("/api/LoginUser", {frmEmail, frmPassword}).then((Result) => {
            if(Result.data.Success){
              router.push({name: 'home'})
            }else{
              ErrorMessage.value = Result.data.Reason
              LoginButton.value.textContent = "Login"
            }
            LoginButton.value.disabled = false        
          }).catch((Error) => {
            console.log(Error)
            LoginButton.value.textContent = "Login"
            ErrorMessage.value = "Internal error. Please contact support"
            LoginButton.value.disabled = false
          })


        }

        return{
          Input_Email,
          Input_Password,
          ErrorMessage,
          LoginButton,
          HandleLogin
        }
    }
}
</script>

<style scoped>
  .divider:after, .divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
  }

  .h-custom {
    height: calc(100% - 73px);
  }

  @media (max-width: 450px) {
    .h-custom {
      height: 100%;
    }
  }

</style>