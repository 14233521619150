<template>
  <div class="auth-form table-view">   
    <div class="card">
      <h5 class="card-header">Filters For Table Below</h5>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6 mb-3">
            <label>Search By Email</label>
            <div class="input-group">              
              <input v-model="Input_SearchByEmail" type="text" class="form-control" placeholder="Start Typing To Search" aria-describedby="button-addon2">
              <button @click="HandleReset" class="btn btn-info" type="button" id="button-addon2">Reset</button>
            </div>            
          </div>
          <div class="col-sm-6 mb-3">
            <label>Search By Name</label>
            <div class="input-group">              
              <input v-model="Input_SearchByName" type="text" class="form-control" placeholder="Start Typing To Search" aria-describedby="button-addon3">
              <button @click="HandleReset" class="btn btn-info" type="button" id="button-addon3">Reset</button>
            </div>            
          </div>          
        </div>
        <div class="row">
          <div class="col-sm-2">            
            <button @click="HandleFilterBtnClick('ACTIVE')" class="btn btn-primary w-100">Active</button>          
          </div>
          <div class="col-sm-2">            
            <button @click="HandleFilterBtnClick('INACTIVE')" class="btn btn-secondary w-100">InActive</button>          
          </div>
          <div class="col-sm-2">            
            <button @click="HandleFilterBtnClick('APPROVED')" class="btn btn-success w-100">Approved</button>          
          </div>
          <div class="col-sm-2">            
            <button @click="HandleFilterBtnClick('NOTAPPROVED')" class="btn btn-dark w-100">Not Approved</button>          
          </div>
        </div>
      </div>
    </div>
    <br> 
    <div class="card">
      <h5 class="card-header">Travel Agency Admin View All Users</h5>
      <div class="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Email</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Created</th>
              <th>Active</th>
              <th>Approved</th>              
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="User in AllUsersFiltered" :key="User._id">
              <td>{{User.UserEmail}}</td>
              <td>{{User.UserFirstName}}</td>          
              <td>{{User.UserLastName}}</td>
              <td>{{moment(User.UserCreated).fromNow()}}</td>
              <td v-if="User.UserActive"><button class="btn btn-sm btn-success no-click">Yes</button></td>
              <td v-else><button class="btn btn-sm btn-warning">No</button></td>
              <td v-if="User.UserApproved"><button class="btn btn-sm btn-success no-click">Yes</button></td>
              <td v-else><button class="btn btn-sm btn-warning no-click">No</button></td>              
              <td><button @click="HandleResetPWClick(User)" class="btn btn-sm btn-outline-danger">Reset Password</button></td>
            </tr>
          </tbody>
        </table>        
      </div>
    </div>
    <br>
    <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
      <div ref="ToastMessage" id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong ref="ToastMessageHeader" class="me-auto"></strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>        
        <div class="toast-body">
          <div class="input-group mb-3">
            <input v-model="Input_NewPassword" type="text" class="form-control" placeholder="New Password" aria-describedby="button-addon2">
            <button ref="SaveNewPWButton" @click="HandleSavePWClick" class="btn btn-success" type="button">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { onMounted, ref, watch } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");

export default {
  setup() {

    const AllUsers = new ref([])
    const AllUsersFiltered = new ref([])
    const ToastMessage = new ref(null)
    const ToastMessageHeader = new ref('')
    const SaveNewPWButton = new ref(null)

    onMounted(() => {


      // Get all users
      axios.get("/api/GetUsersInMyTAAsManager").then((Result) => {
        if(Result.data.Success){
          AllUsers.value = Result.data.Users
          AllUsersFiltered.value = Result.data.Users
        }else{
          console.log("Error")
        }
      }).catch((Error) => {
        console.log(Error)
        if(Error.response.status !== undefined || Error.response.status === 401){
          router.push({name: 'home'})
        }
      })



    })

    const HandleReset = () => {

      Input_SearchByEmail.value = ''
      Input_SearchByName.value = ''

      AllUsersFiltered.value = AllUsers.value

    };

    const Input_SearchByEmail = new ref('')
    const Input_SearchByName = new ref('')

    watch(Input_SearchByEmail, () => {

      AllUsersFiltered.value = AllUsers.value.filter((a) => {
        return a.UserEmail.includes(Input_SearchByEmail.value.toUpperCase())
      })//.slice can be added

    })

    watch(Input_SearchByName, () => {
      AllUsersFiltered.value = AllUsers.value.filter((a) => {
        return a.UserFirstName.includes(Input_SearchByName.value.toUpperCase()) || (a.UserLastName.includes(Input_SearchByName.value.toUpperCase()))
      })//.slice can be added
    })

    const HandleFilterBtnClick = (sType) => {

      switch(sType.toUpperCase()){

        case "ACTIVE" : {
          AllUsersFiltered.value = AllUsers.value.filter((a) => {
            return a.UserActive
          })
        }break;

        case "INACTIVE" : {
          AllUsersFiltered.value = AllUsers.value.filter((a) => {
            return !a.UserActive
          })
        }break;

        case "APPROVED" : {
          AllUsersFiltered.value = AllUsers.value.filter((a) => {
            return a.UserApproved
          })
        }break;

        case "NOTAPPROVED" : {
          AllUsersFiltered.value = AllUsers.value.filter((a) => {
            return !a.UserApproved
          })
        }break;

        case "ADMIN" : {
          AllUsersFiltered.value = AllUsers.value.filter((a) => {
            return a.UserAdmin
          })
        }break;

        case "NOTADMIN" : {
          AllUsersFiltered.value = AllUsers.value.filter((a) => {
            return !a.UserAdmin
          })
        }break;

      }     

    }

    var SelectedUserID = ''

    const HandleResetPWClick = (User) => {

      ToastMessageHeader.value.textContent = "Change Password For: " + User.UserFirstName
      ToastMessage.value.classList.add("show")
      SelectedUserID = User._id

    }

    const Input_NewPassword = new ref('')
    

    const HandleSavePWClick = () => {

      SaveNewPWButton.value.disabled = true

      const frmUserID = SelectedUserID;
      const frmNewPassword = Input_NewPassword.value

      if(frmNewPassword.length < 8){
        ToastMessageHeader.value.textContent = "Password must be at least 8 characters!"
        SaveNewPWButton.value.disabled = false
        return;
      }

      axios.post("/api/TAAdminChangeUserPassword", {frmUserID, frmNewPassword}).then((Result) => {
        if(Result.data.Success){
          ToastMessageHeader.value.textContent = "Password has been changed successfully"
        }else{
          ToastMessageHeader.value.textContent = Result.data.Reason
          SaveNewPWButton.value.disabled = false
        }
      }).catch((Error) => {
        console.log(Error)
        if(Error.response.status !== undefined || Error.response.status === 401){
          router.push({name: 'home'})
        }
      })

    }

    return {
      HandleReset,
      moment,
      AllUsers,
      Input_SearchByEmail,
      Input_SearchByName,
      AllUsersFiltered,
      HandleFilterBtnClick,
      HandleResetPWClick,
      ToastMessage,
      ToastMessageHeader,
      HandleSavePWClick,
      Input_NewPassword,
      SaveNewPWButton
    };
  },
};
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}

.no-click:hover{
  cursor: default;
}
</style>