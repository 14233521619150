<template>
  <div class="normal-view auth-form">

    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <h5 class="card-header">Admin Panel</h5>
          <div class="card-body">
            <h5 class="card-title">All Admin features can be managed from this page</h5>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-sm-6 d-flex">
        <div class="card flex-fill">
          <div class="card-body">
            <h5 class="card-title lead text-primary">User Management</h5>
            <p class="card-text">Create, Edit and Manage Users</p>
            <div class="list-group">
              <router-link class="list-group-item list-group-item-action" to="/admin-users">View All Users</router-link>
              <router-link class="list-group-item list-group-item-action" to="/admin-pending-users">View Pending Users</router-link>
            </div>                                  
          </div>
        </div>
      </div>
      <div class="col-sm-6 d-flex">
        <div class="card flex-fill">
          <div class="card-body">
            <h5 class="card-title lead text-primary">Travel Agency Management</h5>
            <p class="card-text">Create, Edit and Manage Travel Agencies</p>
            <div class="list-group">
              <router-link class="list-group-item list-group-item-action" to="/admin-travel-agencies">View All Travel Agencies</router-link>
              <router-link class="list-group-item list-group-item-action" to="/admin-create-travel-agency">Create A Travel Agency</router-link>
            </div>                                  
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>

import { onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");

export default {
  components: {},
    setup(){

        axios.get("/api/IsLoggedIn").then((Result) => {
          if(Result.data.Success){
            if(!Result.data.IsAdmin){
              router.push({name: 'home'})
            }
          }else{
            router.push({name: 'home'})
          }
        }).catch((Error) => {
          console.log(Error)
          router.push({name: 'home'})
        })

        return{}
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}
</style>