<template>
  <div class="auth-form normal-view">
    <div ref="card2" class="card border-primary">
      <h5 class="card-header">Admin Manage User</h5>
      <div class="card-body">
        <form @submit.prevent="HandleUpdate">
          <div class="mb-3">
            <label class="form-label">Current Travel Agency</label>
            <div class="input-group">
              <input v-model="Input_TA" disabled type="text" class="form-control" aria-describedby="button-addon2">
              <button @click="HandleResetTAClick" class="btn btn-warning" type="button" id="btnReset">Reset</button>
            </div>            
          </div>  
          <div class="mb-3 col-sm-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon3">Type To Search</span>
              <input v-model="Input_TASearchTerm" v-on:input="UpdateTravelAgencySearch" type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3">
            </div>
            <div v-for="TA in SearchedTAs" :key="TA._id" class="list-group">
              <button @click="HandleSearchTermClick(TA)" type="button" class="list-group-item list-group-item-action list-group-hover-color">{{TA.TATradingAs}}</button>              
            </div>
            <small class="text-muted">Search For A Travel Agency And Click To Assign Travel Agency To User</small>
          </div>
          <hr>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <input required v-model="Input_Email" type="email" class="form-control" id="frmEmail" />
          </div>
          <div class="mb-3">
            <label class="form-label">First Name</label>
            <input required v-model="Input_FirstName" type="text" class="form-control" id="frmFirstName" />
          </div>
          <div class="mb-3">
            <label class="form-label">Last Name</label>
            <input required v-model="Input_LastName" type="text" class="form-control" id="frmLastName" />
          </div>
          <div class="mb-3">
            <div class="form-check form-switch">
              <input v-model="InputCBX_Active" class="form-check-input" type="checkbox" id="frmCBXActive">
              <label class="form-check-label">User Is Active</label>
            </div>
          </div>
          <div class="mb-3">
            <div class="form-check form-switch">
              <input v-model="InputCBX_Approved" class="form-check-input" type="checkbox" id="frmCBXApproved">
              <label class="form-check-label">User Is Approved</label>
            </div>
          </div>
          <div class="mb-3">
            <div class="form-check form-switch">
              <input v-model="InputCBX_Admin" class="form-check-input" type="checkbox" id="frmCBXAdmin">
              <label class="form-check-label">User Is Admin</label>
            </div>            
          </div>
          <div class="mb-3">
            <div v-if="InputCBX_Admin" class="alert alert-danger">This User Will Be Given Admin Rights. Please Take Caution</div>
          </div>
          <div class="mb-3">            
            <button disabled ref="UpdateButton" type="submit" class="btn btn-info">Update</button>    
          </div>  
          <div class="mb-3">            
            <button @click="HandleWelcomeClick" disabled ref="WelcomeButton" type="button" class="btn btn-secondary">Send Welcome Email</button>    
          </div>          
          <div class="mb-3">
            <label>Change Password</label>
            <div class="input-group">            
              <input v-model="Input_NewPassword" type="text" class="form-control" placeholder="Change Password" aria-describedby="button-addon2">
              <button @click="HandleChangePassword" disabled ref="ChangePWButton" class="btn btn-warning" type="button">Change Password</button>
            </div>
          </div>
          <hr>
          <div v-if="DeleteWarning" class="mb-3">
            <div class="alert alert-warning">{{DeleteWarning}}</div>
          </div>
          <div class="mb-3">            
            <button @click="HandleDeleteUser" disabled ref="DeleteUserButton" type="button" class="btn btn-danger">Delete User</button>    
          </div>
          
          <div v-if="ErrorMessage">
            <hr>
            <div class="alert alert-danger">
              {{ErrorMessage}}
            </div>
          </div>      
        </form>
      </div>      
    </div>
    <br>
  </div>
</template>

<script>

import { onMounted, ref, watch } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");
import { useRoute } from 'vue-router';

export default {

    setup(){

        const UpdateButton = new ref(null)
        const ChangePWButton = new ref(null)
        const DeleteUserButton = new ref(null)
        const WelcomeButton = new ref(null)
        let sUserID = useRoute().params.id;
        const ErrorMessage = new ref('')

        var CurrentUser = {}
        var SelectedTAID = ""

        const TAs = new ref([])
        const SearchedTAs = new ref([])

        const Input_TA = new ref('')
        const Input_Email = new ref('')
        const Input_FirstName = new ref('')
        const Input_LastName = new ref('')
        const InputCBX_Active = new ref()
        const InputCBX_Admin = new ref()
        const InputCBX_Approved = new ref()
        const Input_NewPassword = new ref('')

        onMounted(() => {

          //Load user first
          let prom1 = axios.get("/api/AdminUser/" + sUserID).then((Result) => {
            if(Result.data.Success){
              CurrentUser = Result.data.User              
            }else{
              console.log('Error')
            }
          }).catch((Error) => {            
            if(Error.response.status !== undefined || error.response.status === 401){
              router.push({name: 'home'})
            }
          })

          let prom2 = axios.get("/api/AdminTAs").then((Result) => {
            if(Result.data.Success){
              TAs.value = Result.data.TAs
              SearchedTAs.value = Result.data.TAs.slice(0, 3)        
            }else{
              console.log('Error')
            }
          }).catch((Error) => {            
            if(Error.response.status !== undefined || Error.response.status === 401){
              router.push({name: 'home'})
            }
          })

          //After both api calls
          Promise.all([prom1, prom2]).then(() => {

            if(CurrentUser.TAID !== ""){
              const TravelAgency = TAs.value.find((a) => {
                return a._id === CurrentUser.TAID
              })
              Input_TA.value = TravelAgency.TATradingAs
              SelectedTAID = TravelAgency._id
            }else{
              Input_TA.value = "No Travel Agency Assigned"
              SelectedTAID = ""
            }
            
            Input_Email.value = CurrentUser.UserEmail
            Input_FirstName.value = CurrentUser.UserFirstName
            Input_LastName.value = CurrentUser.UserLastName
            InputCBX_Active.value = CurrentUser.UserActive
            InputCBX_Admin.value = CurrentUser.UserAdmin
            InputCBX_Approved.value = CurrentUser.UserApproved

            UpdateButton.value.disabled = false
            ChangePWButton.value.disabled = false
            DeleteUserButton.value.disabled = false
            WelcomeButton.value.disabled = false

            
          })


        })

        const Input_TASearchTerm = new ref('')

        watch(Input_TASearchTerm, () => {
          //This will run everytime we change input on InputTASearchTerm

          if(Input_TASearchTerm.value === ""){
            SearchedTAs.value = TAs.value.slice(0, 3)
            return;
          }

          SearchedTAs.value = TAs.value.filter((a) => {
            return a.TATradingAs.includes(Input_TASearchTerm.value.toUpperCase())
          }).slice(0,3)

        })

        const HandleSearchTermClick = (TA) => {
          Input_TA.value = TA.TATradingAs
          SelectedTAID = TA._id
        }

        const HandleResetTAClick = () => {
          if(CurrentUser.TAID !== ""){
              const TravelAgency = TAs.value.find((a) => {
                return a._id === CurrentUser.TAID
              })
              Input_TA.value = TravelAgency.TATradingAs
              SelectedTAID = TravelAgency._id
            }else{
              Input_TA.value = "No Travel Agency Assigned"
              SelectedTAID = ""
            }
        }

        const HandleUpdate = () => {

          UpdateButton.value.disabled = true
          ErrorMessage.value = ""

          const frmTAID = SelectedTAID
          const frmEmail = Input_Email.value
          const frmFirstName = Input_FirstName.value
          const frmLastName = Input_LastName.value
          const frmUserActive = InputCBX_Active.value
          const frmUserApproved = InputCBX_Approved.value
          const frmUserAdmin = InputCBX_Admin.value
          const frmUserID = sUserID

          axios.post("/api/AdminUpdateUserProfile", {
            frmUserID,
            frmTAID,
            frmEmail,
            frmFirstName,
            frmLastName,
            frmUserActive,
            frmUserApproved,
            frmUserAdmin
          }).then((Result) => {
            if(Result.data.Success){
              UpdateButton.value.classList.remove("btn-info")  
              UpdateButton.value.classList.add("btn-success")  
              UpdateButton.value.textContent = "Done"
            }else{
              ErrorMessage.value = Result.data.Reason
              UpdateButton.value.disabled = false
            }
          }).catch((Error) => {
            console.log(Error)
            ErrorMessage.value = "API Error. Please Contact Support"
          })

        }

        const HandleChangePassword = () => {
          
          ChangePWButton.value.disabled = true

          const frmNewPassword = Input_NewPassword.value

          axios.post("/api/AdminResetUserPassword/" + sUserID, {frmNewPassword}).then((Result) => {
            if(Result.data.Success){
              ChangePWButton.value.textContent = "Done"              
            }else{
              ErrorMessage.value = Result.data.Reason
            }
            ChangePWButton.value.disabled = false
          }).catch((Error) => {
            console.log(Error);
            ErrorMessage.value = "API Error. Contact support"
            ChangePWButton.value.disabled = false
          })

        }

        const DeleteWarning = new ref('')

        const HandleDeleteUser = () => {

          if(!DeleteWarning.value){
            DeleteWarning.value = "Warning! Deleting a user cannot be undone. Click the button again to delete this user."
            return
          }

          DeleteUserButton.value.disabled = true

          const frmID = sUserID;

          axios.post("/api/AdminDeleteUser", {frmID}).then((Result) => {
            if(Result.data.Success){
              DeleteWarning.value = "Deleted successfully"
            }else{
              DeleteUserButton.value.disabled = false
            }
          }).catch((Error) => {
            console.log(Error);
            ErrorMessage.value = "API Error. Contact support"
            DeleteUserButton.value.disabled = false
          })
          
        }

        const HandleWelcomeClick = () => {

          WelcomeButton.value.disabled = true

          const frmID = sUserID;

          axios.post("/api/AdminSendApprovedEmail", {frmID}).then((Result) => {            
            if(Result.data.Success){
               WelcomeButton.value.textContent = "Done"
            }else{
              ErrorMessage.value = Result.data.Reason
              WelcomeButton.value.disabled = false
            }
          }).catch((Error) => {
            console.log(Error);
            ErrorMessage.value = "API Error. Contact support"
            WelcomeButton.value.disabled = false
          })

        }

        return{
          UpdateButton,
          WelcomeButton,
          HandleWelcomeClick,
          TAs,
          SearchedTAs,
          Input_TA,
          Input_Email,
          Input_FirstName,
          Input_LastName,
          InputCBX_Active,
          InputCBX_Admin,
          InputCBX_Approved,
          ErrorMessage,
          Input_TASearchTerm,
          HandleSearchTermClick,
          HandleResetTAClick,
          HandleUpdate,
          ChangePWButton,
          Input_NewPassword,
          HandleChangePassword,
          DeleteUserButton,
          HandleDeleteUser,
          DeleteWarning
        }
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}

.list-group-hover-color:hover{
  background-color: #0D6EFD;
  color: white;
}
</style>