<template>
  <div class="normal-view">
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>        
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../assets/home/1.jpg" class="d-block w-100" alt="...">
          <div class="carousel-caption d-none d-md-block">
            <a href='https://beds.a2ztravelgroups.com' target="_blank" class="btn btn-lg btn-success">Hotel Portal</a>         
          </div>
        </div>
        <div class="carousel-item">      
          <img src="../assets/home/2.jpg" class="d-block w-100" alt="...">
          <div class="carousel-caption d-none d-md-block">
            <a href='https://www.dv.a2ztravelgroups.com' target="_blank" class="btn btn-lg btn-success">Dubai Visa Portal</a>           
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
</div>

</template>

<script>

import { onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");

export default {
  components: {},
    setup(){ 

      return {}
    }
}
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
}
</style>