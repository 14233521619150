<template>
  <section class="vh-100">
  <div class="container-fluid h-custom">
    <div class="row d-flex justify-content-center h-100">
     
      <div class="col-md-10 col-lg-8 col-xl-6">

        <h1 class="text-white">A2Z Travel Groups</h1>
        <span class="lead text-white">Agents Portal</span>
        <hr>
        <div class="alert alert-info lead">A reset OTP (One Time Pin) will be sent to your registered Email Address. Please enter the OTP below to reset your password.</div>

        <hr>

        <form @submit.prevent="HandleForgotPW">
        
          <div class="form-outline mb-4">
            <label class="form-label lead text-white">Email Address</label>
            <div class="input-group">
              <input v-model="Input_Email" type="email" class="form-control form-control-lg" placeholder="Email Address">
              <button ref="RequestOTPButton" @click="HandleRequestOTP" class="btn btn-warning" type="button">Request OTP</button>
            </div>     
            <span v-if="OTPResponse" class="lead text-info">{{OTPResponse}}</span>       
          </div>
        
          <div class="form-outline mb-4">
            <label class="form-label lead text-white">One Time Pin (OTP)</label>
            <input v-model="Input_OTP" required type="text" class="form-control form-control-lg" placeholder="One Time Pin" />
          </div>

          <div class="form-outline mb-3">
            <label class="form-label lead text-white">New Password</label>
            <input minlength="8" v-model="Input_Password" required type="password" class="form-control form-control-lg" placeholder="Password" />            
          </div>

          <div class="form-outline mb-3">
            <label class="form-label lead text-white">Confirm New Password</label>
            <input minlength="8" v-model="Input_PasswordConfirm" required type="password" class="form-control form-control-lg" placeholder="Confirm Password" />            
          </div>

          <div v-if="ErrorMessage" class="form-outline mb-3">
            <div class="alert alert-danger">{{ErrorMessage}}</div>
          </div>


          <div class="text-center text-lg-start mt-4 pt-2">
            <button ref="ResetPWButton" type="submit" class="btn btn-primary btn-lg" style="padding-left: 2.5rem; padding-right: 2.5rem;">Reset</button>            
          </div>          

        </form>
      </div>
    </div>
  </div>

</section>
</template>

<script>

import { onMounted, ref } from "vue"
import axios from "axios"
import router from "@/router"


export default {
    setup(){

        const Input_Email = new ref('')
        const Input_OTP = new ref('')
        const Input_Password = new ref('')
        const Input_PasswordConfirm = new ref('')
        const ErrorMessage = new ref('')
        const ResetPWButton = new ref(null)
        const RequestOTPButton = new ref(null)

        const HandleForgotPW = () => {
          
          ResetPWButton.value.disabled = true
          ResetPWButton.value.textContent = "Please wait..."
          ErrorMessage.value = ""

          if(Input_Password.value.length < 8){
            ErrorMessage.value = "Password must be at least 8 characters"
            return
          }

          if(Input_Password.value !== Input_PasswordConfirm.value){
            ErrorMessage.value = "Passwords do not match"
            return
          }

          const frmEmail = Input_Email.value
          const frmPassword = Input_Password.value        
          const frmOTP = Input_OTP.value

          axios.post("/api/UserResetPassword", {frmEmail, frmOTP, frmPassword}).then((Result) => {
            if(Result.data.Success){
              ErrorMessage.value = "Your password has been changed. Redirecting to Login..."
              setTimeout(() => {
                router.push({name: 'login'})
              }, 3000);
            }else{
              ErrorMessage.value = Result.data.Reason
              ResetPWButton.value.textContent = "Reset"
              ResetPWButton.value.disabled = false   
            };            
          }).catch((Error) => {
            console.log(Error)
            ResetPWButton.value.textContent = "Reset"
            ErrorMessage.value = "Internal error. Please contact support"
            ResetPWButton.value.disabled = false
          })


        }

        const OTPResponse = new ref('')

        const HandleRequestOTP = () => {

          RequestOTPButton.value.textContent = "Please wait..."
          RequestOTPButton.value.disabled = true

          const frmEmail = Input_Email.value

          axios.post("/api/RequestPWOTP", {frmEmail}).then((Result) => {
            if(Result.data.Success){
              RequestOTPButton.value.textContent = "Done"
              OTPResponse.value = "OTP has been sent to your Email. Please check your junk/spam folder if you have not received the Email. The OTP Code is valid for 20 minutes from time of request."
            }else{
              OTPResponse.value = Result.data.Reason
            }
          }).catch((Error) => {
            console.log(Error)
            ErrorMessage.value = "Internal error. Please contact support"
          })

        }

        return{
          Input_Email,
          Input_Password,
          Input_PasswordConfirm,
          ErrorMessage,
          ResetPWButton,
          HandleForgotPW,
          Input_OTP,
          HandleRequestOTP,
          RequestOTPButton,
          OTPResponse
        }
    }
}
</script>

<style scoped>
  .divider:after, .divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
  }

  .h-custom {
    height: calc(100% - 73px);
  }

  @media (max-width: 450px) {
    .h-custom {
      height: 100%;
    }
  }

</style>