<template>
<div class="normal-view">

  <div class="auth-form">

    <div v-if="IsAdmin" class="card">
      <h5 class="card-header bg-info">You are the manager of this Travel Agency</h5>
      <div class="card-body">
        <router-link to="/travel-agency-users" class="btn btn-primary">Manage Travel Agency Users</router-link>
      </div>
    </div>

    <br>

    <div class="card">
      <h5 class="card-header">My Travel Agency</h5>
      <div class="card-body">
        <form>
          <div class="mb-3">
            <label class="form-label">Travel Agency Name</label>
            <input disabled v-model="Input_TAName" type="text" class="form-control" />
          </div>
          <div class="mb-3">
            <label class="form-label">Travel Agency Trading As</label>
            <input disabled v-model="Input_TATradingAs" type="text" class="form-control" />
          </div>  
          <div class="mb-3">
            <label class="form-label">Travel Agency Address Line 1</label>
            <input disabled v-model="Input_TAAddressL1" type="text" class="form-control" />
          </div>  
          <div class="mb-3">
            <label class="form-label">Travel Agency Address Line 2</label>
            <input disabled v-model="Input_TAAddressL2" type="text" class="form-control" />
          </div>  
          <div class="mb-3">
            <label class="form-label">Travel Agency Postal Code</label>
            <input disabled v-model="Input_TAPostal" type="text" class="form-control" />
          </div>
          <div class="mb-3">
            <label class="form-label">Travel Agency City</label>
            <input disabled v-model="Input_TACity" type="text" class="form-control" />
          </div>
          <div class="mb-3">
            <label class="form-label">Travel Agency Country</label>
            <input disabled v-model="Input_TACountry" type="text" class="form-control" />
          </div>
          <div class="mb-3">
            <label class="form-label">Travel Agency Manager</label>
            <input disabled v-model="Input_TAManager" type="text" class="form-control" />
          </div>
          <div class="mb-3">
            <label class="form-label">Travel Agency Manager Contact</label>
            <input disabled v-model="Input_TAManagerContact" type="text" class="form-control" />
          </div>
          <div class="mb-3">
            <label class="form-label">Travel Agency Manager Email</label>
            <input disabled v-model="Input_TAManagerEmail" type="email" class="form-control" />
          </div>
          <div class="mb-3">
            <label class="form-label">Travel Agency Date Created</label>
            <input disabled v-model="Input_TACreated" type="text" class="form-control" />
          </div>    
          <div class="mb-3">
            <div class="alert alert-warning">Please contact support to change details. Change of details will require validation</div>
          </div>        
          <div v-if="ErrorMessage">
            <hr>
            <div class="alert alert-danger">
              {{ErrorMessage}}
            </div>
          </div>      
        </form>
      </div>      
    </div>
  </div>

</div>
</template>

<script>

import { onMounted, ref } from "vue";
import axios from "axios";
import router from "@/router";
const moment = require("moment");

export default {
  setup() {

    const Input_TAName = ref('')
    const Input_TATradingAs = ref('')
    const Input_TAAddressL1 = ref('')
    const Input_TAAddressL2 = ref('')
    const Input_TAPostal = ref('')
    const Input_TACity = ref('')
    const Input_TACountry = ref('')
    const Input_TAManager = ref('')
    const Input_TAManagerContact = ref('')
    const Input_TAManagerEmail = ref('')
    const Input_TACreated = ref('')
    const ErrorMessage = ref('')
    const IsAdmin = ref(false)

    //Load data into the form first
    onMounted(() => {
      
      axios.get('/api/GetUserTA').then((Result) => {
        if(Result.data.Success){

          IsAdmin.value = Result.data.TAAdmin
          Input_TAName.value = Result.data.TA.TAName
          Input_TATradingAs.value = Result.data.TA.TATradingAs
          Input_TAAddressL1.value = Result.data.TA.TAAddressLine1
          Input_TAAddressL2.value = Result.data.TA.TAAddressLine2
          Input_TAPostal.value = Result.data.TA.TAPostalCode
          Input_TACity.value = Result.data.TA.TACity
          Input_TACountry.value = Result.data.TA.TACountry
          Input_TAManager.value = Result.data.TA.TAManagerName
          Input_TAManagerContact.value = Result.data.TA.TAManagerContactNumber
          Input_TAManagerEmail.value = Result.data.TA.TAManagerEmail
          Input_TACreated.value = moment(Result.data.TA.TADateCreated).format('LLLL') + "  (" + moment(Result.data.TA.TADateCreated).fromNow() + ")"

        }else{
          ErrorMessage.value = Result.data.Reason
        }
      }).catch((Error) => {
        console.log(Error)
        ErrorMessage.value = "An API Error Occured. Please Try Again Later Or Contact Admin"
      })

    })

    return {
      Input_TAName,
      Input_TATradingAs,
      Input_TAAddressL1,
      Input_TAAddressL2,
      Input_TAPostal,
      Input_TACity,
      Input_TACountry,
      Input_TAManager,
      Input_TAManagerContact,
      Input_TAManagerEmail,
      Input_TACreated,
      ErrorMessage,
      IsAdmin
    };
  },
};
</script>

<style scoped>
.auth-form {
  padding-top: 2rem;
  padding-bottom: 3rem;
}
</style>