<template>
  <div>
    <NavBarComponent v-if="$route.path !== '/login' && $route.path !== '/register' && $route.path !== '/forgot-password'"/>
    <router-view/>
  </div>
</template>

<script>

import NavBarComponent from "./components/NavBarComponent.vue";
import axios from "axios";
import {onUpdated, ref} from 'vue'
import router from "@/router";

export default {
  components: { NavBarComponent },

  setup() {  
    
    
    return {}

  },
};
</script>

<style>

body{

  background: #005C97;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #363795, #005C97);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #363795, #005C97); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.normal-view{
  max-width: 60%;
  margin: auto;    
}

.table-view{
  max-width: 80%;
  margin: auto;
}

@media only screen and (max-width: 600px) {

  .normal-view{
    max-width: 96%;
    margin: auto;    
  }

  .table-view{
    max-width: 96%;
    margin: auto;
  }
}

</style>
