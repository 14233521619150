import { createRouter, createWebHistory } from 'vue-router'

//Authentication
import LoginView from '../views/authentication/LoginView.vue'
import RegisterView from '../views/authentication/RegisterView.vue'
import ForgotPasswordView from '../views/authentication/ForgotPasswordView.vue'

//Homes
import HomeView from '../views/HomeView.vue'
import AdminView from '../views/admin/AdminView.vue'

import ProfileView from '../views/profile/ProfileView.vue'
import TravelAgencyView from '../views/travel-agency/TravelAgencyView.vue'
import TravelAgencyUsersView from '../views/travel-agency/TravelAgencyUsersView.vue'

import AdminUsersView from '../views/admin/AdminUsersView.vue'
import AdminPendingUsersView from '../views/admin/AdminPendingUsersView.vue'
import AdminManageUserView from '../views/admin/AdminManageUserView.vue'

import AdminTAsView from '../views/admin/AdminTAsView.vue'
import AdminCreateTAView from '../views/admin/AdminCreateTAView.vue'
import AdminManageTAView from '../views/admin/AdminManageTAView.vue'
import AdminTAUsersView from '../views/admin/AdminTAUsersView.vue'

import axios from "axios";

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
  },
  {
    path: '/travel-agency',
    name: 'travelagency',
    component: TravelAgencyView,
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
  },
  {
    path: '/admin-users',
    name: 'adminusers',
    component: AdminUsersView,
  },
  {
    path: '/admin-pending-users',
    name: 'adminpendingusers',
    component: AdminPendingUsersView,
  },
  {
    path: '/admin-manage-user/:id',
    name: 'adminmanageuser',
    component: AdminManageUserView,
  },
  {
    path: '/admin-travel-agencies',
    name: 'admintasview',
    component: AdminTAsView,
  },
  {
    path: '/admin-create-travel-agency',
    name: 'admincreateta',
    component: AdminCreateTAView,
  },
  {
    path: '/admin-manage-travel-agency/:id',
    name: 'adminmangeta',
    component: AdminManageTAView,
  },
  {
    path: '/forgot-password',
    name: 'forgotpassword',
    component: ForgotPasswordView,
  },
  {
    path: '/travel-agency-users',
    name: 'travelagencyusers',
    component: TravelAgencyUsersView,
  },
  {
    path: '/admin-travel-agency-users/:id',
    name: 'admintravelagencyusers',
    component: AdminTAUsersView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  
  var bResult = false;

  let prom1 = axios.get('/api/IsLoggedIn').then((Result) => {
    if(Result.data.Success){
      bResult = true
    }
  }).catch((Error) => {
    console.log(Error)
  })

  Promise.all([prom1]).then(() => {

    if(to.name === 'login'){

      if(bResult){
        //We are logged in
        next({name: 'home'})
      }else{
        next();
      }
      
    } else if(to.name === 'register'){

      if(bResult){
        next({name: 'home'})
      }else{
        next()
      }

    }else if(to.name === 'forgotpassword'){

      if(bResult){
        next({name: 'home'})
      }else{
        next()
      }

    }else if(!bResult){
      next({name: 'login'})
    }else{
      next()
    }

    

    // if(to.name === 'register' && !bResult){
    //   next()
    // }else if(to.name !== "login" && !bResult){      
    //   next({name: 'login'})
    // }else if(to.name === 'login' && bResult){
    //   next({name: from.name})
    // }else if(to.name === 'register' && bResult){
    //   next({name: from.name})
    // } else next()
  })

})


export default router