<template>
  <section class="vh-100">
  <div class="container-fluid h-custom">
    <div class="row d-flex justify-content-center h-100">
     
      <div class="col-md-10 col-lg-8 col-xl-6">

        <h1 class="text-white">A2Z Travel Groups</h1>
        <span class="lead text-white">Agents Portal</span>
        <hr>
        <div class="alert alert-info lead">Your details will be verified before access to the system is granted</div>

        <hr>

        <form @submit.prevent="HandleRegister">

          <div class="form-outline mb-4">
            <label class="form-label lead text-white">First Name</label>
            <input v-model="Input_FirstName" required type="text" class="form-control form-control-lg" placeholder="First Name" />
          </div>

          <div class="form-outline mb-4">
            <label class="form-label lead text-white">Last Name</label>
            <input v-model="Input_LastName" required type="text" class="form-control form-control-lg" placeholder="Last Name" />
          </div>
        
          <div class="form-outline mb-4">
            <label class="form-label lead text-white">Email Address</label>
            <input v-model="Input_Email" required type="text" class="form-control form-control-lg" placeholder="Email Address" />
          </div>
        
          <div class="form-outline mb-3">
            <label class="form-label lead text-white">Password</label>
            <input minlength="8" v-model="Input_Password" required type="password" class="form-control form-control-lg" placeholder="Password" />            
          </div>

          <div class="form-outline mb-3">
            <label class="form-label lead text-white">Confirm Password</label>
            <input minlength="8" v-model="Input_PasswordConfirm" required type="password" class="form-control form-control-lg" placeholder="Confirm Password" />            
          </div>

          <div v-if="ErrorMessage" class="form-outline mb-3">
            <div class="alert alert-danger">{{ErrorMessage}}</div>
          </div>


          <div class="text-center text-lg-start mt-4 pt-2">
            <button ref="RegisterButton" type="submit" class="btn btn-primary btn-lg" style="padding-left: 2.5rem; padding-right: 2.5rem;">Register</button>            
          </div>          

        </form>
      </div>
    </div>
  </div>

</section>
</template>

<script>

import { onMounted, ref } from "vue"
import axios from "axios"
import router from "@/router"


export default {
    setup(){

        const Input_Email = new ref('')
        const Input_Password = new ref('')
        const Input_FirstName = new ref('')
        const Input_LastName = new ref('')
        const Input_PasswordConfirm = new ref('')
        const ErrorMessage = new ref('')
        const RegisterButton = new ref(null)

        const HandleRegister = () => {
          
          RegisterButton.value.disabled = true
          RegisterButton.value.textContent = "Please wait..."
          ErrorMessage.value = ""

          if(Input_Password.value.length < 8){
            ErrorMessage.value = "Password must be at least 8 characters"
            return
          }

          if(Input_Password.value !== Input_PasswordConfirm.value){
            ErrorMessage.value = "Passwords do not match"
            return
          }

          const frmFirstName = Input_FirstName.value
          const frmLastName = Input_LastName.value
          const frmEmail = Input_Email.value
          const frmPassword = Input_Password.value        

          axios.post("/api/RegisterUser", {frmFirstName, frmLastName, frmEmail, frmPassword}).then((Result) => {
            if(Result.data.Success){
              ErrorMessage.value = "Thank you for registering. You will receive an Email once your account has been activated"
              RegisterButton.value.textContent = "Register"
            }else{
              ErrorMessage.value = Result.data.Reason
              RegisterButton.value.textContent = "Register"
              RegisterButton.value.disabled = false   
            };            
          }).catch((Error) => {
            console.log(Error)
            RegisterButton.value.textContent = "Register"
            ErrorMessage.value = "Internal error. Please contact support"
            RegisterButton.value.disabled = false
          })


        }

        return{
          Input_PasswordConfirm,
          Input_FirstName,
          Input_LastName,
          Input_Email,
          Input_Password,
          ErrorMessage,
          RegisterButton,
          HandleRegister
        }
    }
}
</script>

<style scoped>
  .divider:after, .divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
  }

  .h-custom {
    height: calc(100% - 73px);
  }

  @media (max-width: 450px) {
    .h-custom {
      height: 100%;
    }
  }

</style>